import React, { Component } from 'react'

export class CurrentTime extends Component {
    constructor(){
        super()
        this.state ={
            currentHour:''
        }
        this.startTick()
    }
     startTick(){
         setInterval(()=>{
            let hour = new Date().toLocaleTimeString()
                      this.setState({currentHour: hour})
       
         }, 1000);
     }
   
    

    render() {
        return (
            <div id="UIReloj" className="clearfix">
                    
                        <p className="horaActuals" id="labelHoraActual">
                        HORA ACTUAL
                        </p>
                    
                    <p id="txt_time" className="d_timeStyle">
                        {this.state.currentHour}
                    </p>
                </div>
        )
    }
}

export default CurrentTime
