import React, { Component } from 'react'
import CardsItemrender from './generic/CardsItemrender';
import { bindActionCreators } from 'redux';
import {getCardsHistory} from '../redux/actions/ActionGame'
import {connect} from 'react-redux'

export class HistoryCards extends Component {
    
    state={
        cartas:[],
        historyLoaded:false,
        newHistory:true,
        undoCard:null,
        lastCardAdded:null,
        game : null,
        idJuego:null
    }
    constructor(props){
        super(props);
    }
    componentWillMount(){
       // this.props.getCardsHistory();
    }
    render() {
       
     
        let removed = false;
        if(this.props.undoCard){
            if(this.state.undoCard){
                if(this.state.undoCard.idcarta != this.props.undoCard.idcarta){
                   
                    this.state.cartas = this.state.cartas.filter(pos => {
                        return pos.idcarta != this.props.undoCard.idcarta;
                      })
                    this.setState({undoCard:this.props.undoCard});
                      removed = true;
                }
            }else{
                if(this.props.undoCard){
                    this.state.cartas = this.state.cartas.filter(pos => {
                        return pos.idcarta != this.props.undoCard.idcarta;
                      })
                      this.setState({undoCard:this.props.undoCard});
                    removed = true;
                }
            }
            
        }
        
           
            if(this.props.gameInfo){
                
                if(this.state.idJuego != this.props.gameInfo.idjuego ){
                    this.props.getCardsHistory(this.props.gameInfo.idjuego);
                    this.setState(
                        {
                            idJuego:this.props.gameInfo.idjuego,
                            newHistory:false,
                            historyLoaded:false,
                            cartas:[]
                        }
                    )

                }
            }
       
       
        //debugger;
        if(this.props.playHistory&&this.state.historyLoaded===false){
           
            if(this.props.playHistory.idjuegoInvoked === this.props.gameInfo.idjuego){
                if(this.props.playHistory.length > 0){
                    this.props.playHistory.map((cardH)=>{
                        this.state.cartas.unshift(cardH)
                    })
                }
                this.setState({historyLoaded:true})

            }
        }
        if(this.props.newCard!=null){
            if(this.props.newCard.valida== 1 && removed === false){
                if(this.state.lastCardAdded !== this.props.newCard){
                    this.state.cartas.unshift(this.props.newCard);
                    this.setState({
                        lastCardAdded : this.props.newCard
                    })
                }
            }
        }
        const itemsRender = this.state.cartas.map((carta)=>{
            return(
                <CardsItemrender key={carta.idcarta} carta={carta}/>
            )
        })
        return (
            <div id="UILogs" className="clearfix">
                <p id="labelHistorial">
                CARTAS QUE YA SALIERON
                </p>
                <div id="UIHistorial" className="clearfix" >
                    {itemsRender}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      playHistory: state.game.playHistory
    }
  }


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({getCardsHistory}, dispatch)
 }
 
  export default connect(mapStateToProps, mapDispatchToProps)(HistoryCards);