import React, { Component } from 'react'
import CurrentTime from './CurrentTime';

export class CurrentGameHeader extends Component {
    
    render() {
        var titulo='cargando...'
        var descripcion = '';
        var jugadaNum = '-';
        var jugadaTotales = '-';
        var premio = 0;
        if(this.props.gameInfo != null){
            descripcion = this.props.gameInfo.descripcion;
            titulo = this.props.gameInfo.titulo;
            jugadaNum = this.props.gameInfo.playInfo.juegoactual;
            jugadaTotales = this.props.gameInfo.juegototal;
            if(this.props.gameInfo.playInfo != null){
                premio = this.props.gameInfo.playInfo.premio;
            }
        }
        return (
            <div className="clearfix, Header">
                <div >
                    <div id="d_tituloPartida" className="divJuegoSombra">
                            <span id="txt_juego" className="titulosJuegoSombra">{titulo} </span>
                        <div className="StyleAJuego">
                                <span id="txt_fecha" className="fechaJuego"> {descripcion} </span>
                        </div>
                        
                    </div>
                        <div id="d_tituloPartida" className="divtitulosJuego">
                            <span id="txt_juego" className="titulosJuego">{titulo} </span>
                    </div>
                </div>
                <div className="HeaderVistozo">
                    <div id="d_tituloPartida" className="divJuegoSombra">
        <span className="titulosJuegoSombra" id="txt_jugada">Jugada {jugadaNum} de {jugadaTotales}</span>
                            
                    </div>
                        <div id="d_tituloPartida" className="divtitulosJuego">
                            <span className="titulosJuego" id="txt_jugada">Jugada {jugadaNum} de {jugadaTotales} </span>
                            <div className="styleJugada" >
                            <span className="fechaJuego fechaJuegot">PREMIO</span>
                                <div className="divPremio" >
                                    <img src="img/Moneda-02.png" className="monedaStyle"></img>
        <span id="txt_premio" className="styleDinero">${premio}.00</span>   
                                </div>
                            
                            </div>
                    {/*<div id="btn_viewChampions" className="styleChampions"><img src="img/Winner-03.png" className="imgstyleChampions"></img></div>*/}
                            
                    
                            
                    </div>
                </div>
                <CurrentTime/>
            </div>
        )
    }
}

export default CurrentGameHeader
