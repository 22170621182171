import React, { Component } from 'react'
import CardLoteria from './generic/CardLoteria'
import { css } from "glamor";
import { bindActionCreators } from 'redux';
import {newCardSignPublish} from '../redux/actions/ActionGame'
import {connect} from 'react-redux'

export class CardSinging extends Component {
    constructor(props){
        super(props)
        this.state={
            numero:props.numeroCarta,
            cartaACantar:[]
        }
    }
    onHide = (event) =>{
        
        
    }
    onUndo = (event) =>{
        
        this.props.onUndoEvent();
        this.props.newCardSignPublish(0, 0)
    }
    onRedo = (event) =>{
        this.props.onRedoEvent();
    }
    onBuscar = (event) =>{
        this.props.onSearchEvent();
    }
    valueSing = (event) =>{
        
        if(event.key === "Enter"){
            let numreg = /^[0-9]+$/;
            let numeroIngresado = event.target.value
            if(numreg.test(numeroIngresado) && (numeroIngresado*1)>0 && (numeroIngresado*1)<=54){
                event.target.value = "";
                this.props.newCardSignPublish(numeroIngresado, this.props.gameInfo.idjuego)
            }else{
                console.log("ERROR");
                event.target.value = "";
            }
            
        }
    }
    
    render() {
        let cartaCantada = "";
        let message = "";
        if(this.props.newCardSign){
            
            if(this.props.newCardSign.valida==0){
            message = <span  className="ToolTipError">HEYYY!! "{this.props.newCardSign.cartainvalida}" ya salió</span>
            }
                const cartasACantar = [this.props.newCardSign];
    
                cartaCantada = cartasACantar.map((carta)=>{
                    return(
                    <CardLoteria key={carta.numero} carta={carta}/>
                    )
                })
                this.props.onNewCardEvent(this.props.newCardSign);
            
        }
        
        /*const cartaCantada = this.state.cartaACantar.map((carta)=>{
            return(
            <CardLoteria key={carta.numero} carta={carta}/>
            )
        })*/
        let overStyle = css({
            ':hover':{
                backgroundColor:'#ffeb80'
            }
        })
        return (
            <div id="UICartasJuego" className="clearfix">
                <div id="centerGroup" className="clearfix">
                <div id="uiactions" className="clearfix">
                    <label id="Text_Input_Group">
                    <p id="labelCantar">
                        Cantar
                    </p>
                    <input id="i_cartaCantar" type="text"  onKeyPress={this.valueSing} keyboardType='numeric'/>
                    </label>
                    <div id="btn_undo" {...overStyle} onClick={this.onUndo} className="btn_AccionesStyle"><img src="img/undo.png" className="IconClass" /></div>
                    {/*<div id="btn_redo" {...overStyle} onClick={this.onRedo} className="btn_AccionesStyle"><img src="img/redo.png" className="IconClass" /></div>*/}
                    <div id="btn_searchPlayer" {...overStyle} onClick={this.onBuscar.bind(this)}className="btn_AccionesStyle"><img src="img/search.png" className="IconClass" /></div>
                </div>
                {message}
                {cartaCantada}
                
               
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        newCardSign: state.game.newCardSign
    }
  }


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({newCardSignPublish}, dispatch)
 }
 
  export default connect(mapStateToProps, mapDispatchToProps)(CardSinging);