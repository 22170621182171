import React, { Component } from 'react'

export class WinnerItemRender extends Component {
    render() {
        return (
            <p id="d_listadoGanadores">
                {this.props.position}.- "{this.props.nombre}"<span className="PremioJugador"> &nbsp;${this.props.premio}</span><br />
            </p>
        )
    }
}

export default WinnerItemRender
