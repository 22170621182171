export default function(state = {}, action){
    switch(action.type){
        case "game": 
            return {...state, game: action.payload }
        case "playHistory": 
            return {...state, playHistory: action.payload }
        case "playerSearch": 
            return {...state, playerSearch: action.payload }
        case "playerCards": 
            return {...state, playerCards: action.payload }
        case "winnersInfo": 
            return {...state, winnersInfo: action.payload }
        case "newCardSign":  
            return {...state, newCardSign: action.payload }            
        case "undoLastCardAction":  
            return {...state, undoLastCardAction: action.payload }
        case "acceptWinnersAction":  
            return {...state, acceptWinnersAction: action.payload }
                                   
        default: 
            return state;      
    }
}
