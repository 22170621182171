import React, { Component } from 'react'
import WinnerItemRender from './WinnerItemRender';

export class UsersWinner extends Component {
    render() {
        const usersToRender = this.props.dataWinners.map((usuario)=>{
            return(
                <WinnerItemRender key={usuario.position} position={usuario.position} nombre={usuario.nombre} premio={usuario.premio} />
            )
        });
        return (
            <div >
               {usersToRender}
            </div>
        )
    }
}

export default UsersWinner
