import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import HeaderGame from './components/HeaderGame'
import HistoryCards from './components/HistoryCards'
import CardSinging from './components/CardSinging'
import GameType from './components/GameType'
import CurrentGameHeader from './components/CurrentGameHeader'

import Winner from './components/Winner'
import PlayerSearch from './components/PlayerSearch'
import PlayerTables from './components/PlayerTables'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'

import { applyMiddleware, createStore } from 'redux'
import packageReducers from './redux/reducers/package-reducers'
import promise from 'redux-promise'
import App from './App'
import { BrowserRouter, Route } from 'react-router-dom'

const creteStoreWithMiddleWare = applyMiddleware(promise)(createStore);
  /*<BrowserRouter>
                    <Route path="/ria/:idGame" component={App}/>
                      <Route path="*" component={App}/>
                    </BrowserRouter> */

ReactDOM.render(
                    <Provider store={creteStoreWithMiddleWare(packageReducers)}>
                      <App></App>
                      

                    </Provider>
                  
, document.querySelector('#root')
)
