import React, { Component } from 'react'

export class CardsItemrender extends Component {
    
    render() {
        const imageToShow = {
            backgroundImage: 'url(' + this.props.carta.url + ')',
            'backgroundAttachment': 'scroll',
            'WebkitBackgroundSize': '100%',
            'backgroundSize': '100%',
            'backgroundPosition': '0px 0px',
            'backgroundRepeat': 'repeat repeat'
        };
        return (
            <div id="mc_cartaLog" className="clearfix, UILogCarta">
                <div id="mc_cartaImage" className="clearfix, ImagenCartaLog" style={imageToShow} >
                </div>
                <p id="txt_carta" className="TituloCartaLog">
                    {this.props.carta.idcarta} - {this.props.carta.alias}
                </p>
            </div>
        )
    }
}

export default CardsItemrender
