import React, { Component } from 'react'

export class CardGameTypeItemRender extends Component {
    render() {
        
        return (
            <div className={this.props.estilo}>
            </div>
        )
    }
}

export default CardGameTypeItemRender
