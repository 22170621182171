import React, { Component } from 'react'
import CardGameTypeItemRender from './generic/CardGameTypeItemRender'
export class GameType extends Component {
    /*state={
        tipoJuego:this.props.tipoJuego
    }*/
    render() {
        console.log("DIBUJANDO GAME TYPE ... ");
         if(!this.props.tipoJuego){
            return(<div></div>)
         }
        var labelTipoJuego = '';
         let cardsGame = {}
        if(this.props.tipoJuego!=null){
            
            labelTipoJuego = this.props.tipoJuego.playInfo.titulo;
            cardsGame = this.props.tipoJuego.playInfo;
        }else{
            labelTipoJuego = 'cargando';
            cardsGame = {
                
                cartasTipoJuego : [{posicion:1, tipoJuego:0},{posicion:2, tipoJuego:0},{posicion:3, tipoJuego:0},{posicion:4, tipoJuego:0},
                                   {posicion:1, tipoJuego:0},{posicion:2, tipoJuego:0},{posicion:3, tipoJuego:0},{posicion:4, tipoJuego:0},
                                   {posicion:1, tipoJuego:0},{posicion:2, tipoJuego:0},{posicion:3, tipoJuego:0},{posicion:4, tipoJuego:0},
                                   {posicion:1, tipoJuego:0},{posicion:2, tipoJuego:0},{posicion:3, tipoJuego:0},{posicion:4, tipoJuego:0}
                                ]
            }            
        }        
        const cartasTipoJuego = cardsGame.cartasTipoJuego.map((carta)=>{
            if(carta == null){
                return
            }
            
            var estilos = "";
            if(carta.tipojuego == 1){
                estilos = 'clearfix, CartaTipoJuegoObjetivo';
            }else{
                estilos = 'clearfix, CartaTipoJuego';
            }
            return(
                <CardGameTypeItemRender key={carta.posicion} estilo={estilos}/>
            )
        })
        return (           
            <div className="GameTypeContainer">
                <p id="labelTipoJuego" className="labelTipoJuegoStyle">
                TIPO DE JUEGO
                </p>
                <div id="UITipoJuego" className="clearfix, UITipoJuegoStyle">
                <div id="UITabla" className="clearfix, UITablaStyle">
                    {cartasTipoJuego}
                   
                </div>
                </div>
                <p id="d_tituloTipoJuego" className="d_tituloTipoJuegoStyle">
                    {labelTipoJuego}
                </p>
                <p id="d_labelEstiloTablas" className="d_labelEstiloTablasStyle">
                TABLAS CLASICAS
                </p>
            </div>
        );
              
    }
}

export default GameType
