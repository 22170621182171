import React, { Component } from 'react'

export class CardLoteria extends Component {
   

    render() {
        
        
        const imageToShow = {
            
            
                backgroundImage: 'url(' + this.props.carta.url + ')',
                'background-attachment': 'scroll',
                '-webkit-background-size': '100%',
                'background-size': '100%',
                'background-position': '0px 0px'            
            
        };
        
        
           return(
            <div id="UICarta" className="clearfix" style={imageToShow}> 
                <p id="d_numero">
                    {this.props.carta.numero}
                </p>
                <p id="d_nombre">
                    {this.props.carta.nombre}
                </p>
            </div>
        )
    }
}

export default CardLoteria
