import React, { Component } from 'react'
import '../css/mainStyles.css'

const getYear = () => {
    const newDate = new Date();
    return newDate.getFullYear();
}
const user = {
    name:'a', lastname:'a', age:0
}
export class HeaderGame extends Component {
    render() {
        return (
        <div></div>
        )
    }
}

export default HeaderGame
