import React, { Component } from 'react'

export class UserSearchIntemRender extends Component {
    onClickEvent = (event) => {
        this.props.onShowCardsEvent(this.props.user)
    }
    render() {
        
        console.log('this.props: ', this.props);
        return (
            <div id="UIJugador" className="clearfix" onClick={this.onShowResults}>
                <p id="d_nombre1">
                {this.props.user.nombre}
                </p>
                <input id="i_vercartas" type="button" defaultValue="Ver cartas" onClick={this.onClickEvent} />
                <p id="d_buenas">
               {this.props.user.countcartasactivas} / {this.props.user.counttotalcartas}<br/>
                </p>
            </div>
        )
    }
}

export default UserSearchIntemRender
