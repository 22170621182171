import React, { Component } from 'react'
import PlayerTables from './PlayerTables';
import UserSearchIntemRender from './generic/UserSearchIntemRender';
import { bindActionCreators } from 'redux';
import {getPlayerSearch, getPlayerCards} from '../redux/actions/ActionGame'
import {connect} from 'react-redux'

export class PlayerSearch extends Component {
    constructor(props){
        super(props)
        this.doSearch = this.doSearch.bind(this);
    }
    state={
        showResults:false,
        listadoDeUsuarios:[],
        nameToSearch:'',
        userData:null
    }
    onCloseEventHandler = (event) =>{
        this.setState({
            showResults : false,
            listadoDeUsuarios:[]
        })
    }
    onShowResults = (event) =>{
        console.log('||||| >>>>> event: ', event);

        this.props.getPlayerCards(event.idcliente, this.props.idPlay)
        this.setState({
            userData:event,
            showResults : true
        })
    }
    doSearch = (event)=>{
        event.preventDefault();
        //ToDO: Consultar API 
        this.props.getPlayerSearch(this.state.nameToSearch, this.props.idPlay)
        /*const user = {
            id:Math.random()*99999,
            nombre:this.state.nameToSearch,
            countCartasActivas:2,
            countTotalCartas:6
        }
        console.log('user: ', user);
        
        this.setState(prevState => ({
             listadoDeUsuarios: [...prevState.listadoDeUsuarios, {user}] 
        })
        )*/
    }
    onTextSearch =  (event)=>{
        this.setState({nameToSearch:event.target.value});
    }
    render() {
        
        let usersToRender
        if(this.props.playerSearch){
            usersToRender = this.props.playerSearch.map((usuario)=>{
                return(
                    <UserSearchIntemRender key={usuario.id} user={usuario} onShowCardsEvent={this.onShowResults} />
                )
            });
        }

        return (
          <div style={{width: '100%', height: '100%'}}>
            <div id="UIAclaracion" className="clearfix">
                <div id="UIBuscador" className="clearfix">
                <label id="formgroup">
                    <p id="labelBuscarJugador">
                    Jugador
                    </p>
                    <input id="i_buscar" type="text" onChange={this.onTextSearch} />
                </label>
                <input id="buscar" type="button" defaultValue="Buscar" onClick={this.doSearch}/>
                <p id="close" onClick={this.props.onCloseSearchEvent}>
                    X
                </p>
                </div>
                <div id="UIListadoJugadores" className="clearfix">
                    {usersToRender}
                </div>
            </div>
            {this.state.showResults?<PlayerTables playerData={this.state.userData} onCloseEvent={this.onCloseEventHandler}/>:null}
          </div>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        playerSearch: state.game.playerSearch
    }
  }


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({getPlayerSearch, getPlayerCards}, dispatch)
 }
 
  export default connect(mapStateToProps, mapDispatchToProps)(PlayerSearch);

